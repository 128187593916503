const routes = [
  {
    index: true,
    label: 'FazziCLAY',
    path: '/',
  },
  {
    label: 'Связаться',
    path: '/contact',
  },
];

export default routes;
